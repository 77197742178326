import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import {
	orderLinesListener,
	orderListener,
	requestIds,
	selectCustomerId,
	selectError,
	selectOrderId,
	setError
} from '../store/shared/cartSlice';
import ConfirmationDialog from '../ui-components/ConfirmationDialog';
import { selectCookieScriptId } from '../store/shared/frontendSlice';

export default function FrontendInitialization(props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const cookieScriptId = useSelector(selectCookieScriptId);
	const orderId = useSelector(selectOrderId);
	const customerId = useSelector(selectCustomerId);
	const error = useSelector(selectError);

	useEffect(() => {
		firebaseService.onAuthStateChanged(async auth => {
			if (auth && !location.pathname.startsWith('/flexpos')) {
				const idTokenResult = await auth.getIdTokenResult();
				if (idTokenResult.claims.organisationId) {
					dispatch(requestIds());
				}
			}
		});
	});

	useEffect(() => {
		if (!location.pathname.startsWith('/flexpos') && cookieScriptId) {
			// cookie script
			const script = document.createElement('script');
			script.setAttribute('type', 'text/javascript');
			script.setAttribute('charSet', 'UTF-8');
			script.setAttribute('src', `//cdn.cookie-script.com/s/${cookieScriptId}.js`);
			document.querySelector('head').append(script);
		}
	}, [location, cookieScriptId]);

	useEffect(() => {
		if (customerId && orderId) {
			const unsubOrder = dispatch(orderListener(customerId, orderId));
			const unsubOrderLines = dispatch(orderLinesListener(customerId, orderId));
			return () => {
				unsubOrder();
				unsubOrderLines();
			};
		}
		return () => {};
	}, [dispatch, customerId, orderId]);

	return (
		<>
			<ConfirmationDialog
				content={error}
				keepMounted
				open={!!error}
				onClose={() => {
					dispatch(setError(null));
				}}
				cancelButton={false}
				okButton="Okay"
			/>
			{props.children}
		</>
	);

	// this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{props.children}</>;
}
