import { datadogRum } from '@datadog/browser-rum';
import config from './datadogServiceConfig';

class DatadogService {
	init() {
		if (window.location.hostname === 'localhost') return false; // No datadog reporting on localhost
		this.datadogRum = datadogRum.init(config);
		return true;
	}
}

const instance = new DatadogService();

export default instance;
