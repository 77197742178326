import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTerms } from '../../../../../../store/shared/frontendSlice';
// eslint-disable-next-line import/no-cycle
import TypesSettings from '../../../TypesConfig';
import { pageListener } from '../../../ContentFirestore';
// eslint-disable-next-line import/no-cycle
import CmsContentElement from '../../../cmsContent/CmsContentElement';

function CartTermsDialog({ open, handleClose }) {
	const terms = useSelector(selectTerms);
	const [heading, setHeading] = useState('');
	const [realTerms, setRealTerms] = useState([]);
	const [termsPage, setTermsPage] = useState({});
	const [embeddedContentElementId, setEmbeddedContentElementId] = useState({});

	useEffect(() => {
		const termsPageListenerUnsubFunc = pageListener('/handelsbetingelser', o => {
			setTermsPage(o);
			if (o.content) {
				Object.entries(o.content).map(([key, value]) => {
					if (value.props.embeddedId && value.props.embeddedId === 'cartTermsDialog') {
						setEmbeddedContentElementId(key);
					}
					return true;
				});
			}
		});
		return () => termsPageListenerUnsubFunc();
	}, []);

	useEffect(() => {
		if (terms.length > 0) {
			const nTerms = [...terms];
			nTerms.splice(0, 1);
			setRealTerms(nTerms);
			setHeading(terms[0]);
		}
	}, [terms]);

	return (
		<Dialog open={open} onClose={handleClose} scroll="paper">
			{termsPage && !termsPage.deleted && termsPage.content && embeddedContentElementId ? (
				<DialogContent dividers>
					<CmsContentElement contentId={embeddedContentElementId} content={termsPage.content} />
				</DialogContent>
			) : (
				<>
					<DialogTitle>{heading.data}</DialogTitle>
					<DialogContent dividers>
						<DialogContentText tabIndex={-1}>
							{realTerms &&
								realTerms.map(c => {
									if (!TypesSettings[c.type]) {
										throw new Error(
											`Type '${c.type}' does not exist in apps/content/TypesConfig.js`
										);
									}
									if (c.data instanceof Object) {
										const nData = { ...c.data };
										nData.small = true;
										return <div key={c.id}>{TypesSettings[c.type](nData)}</div>;
									}
									return <div key={c.id}>{TypesSettings[c.type](c.data)}</div>;
								})}
							{/* <span className="font-bold">Generelle oplysninger</span> */}
							{/* <br /> */}
							{/* Museum Jorn <br /> Gudenåvej 7-9 <br /> 8600 Silkeborg <br /> CVR nr.: 26623928 <br /> Telefon: */}
							{/* 86825388 <br /> */}
							{/* Email: info@museumjorn.dk <br /> <br /> <span className="font-bold">Priser</span> <br /> Hos Museum */}
							{/* Jorn er alle priserne i danske kroner. Billetter er momsfri og vil fremgå specifikt at kvitteringen. */}
							{/* Vi forbeholder os ret til fra dag til dag at ændre i priserne uden forudgående samtykke. Der tages */}
							{/* forbehold for udsolgte arrangementer. <br /> <br /> */}
							{/* <span className="font-bold">Betaling</span> */}
							{/* <br /> */}
							{/* Museum Jorn modtager betaling med VISA-Dankort, VISA, VISA Electron, Mastercard. Betalingen vil */}
							{/* blive trukket på din konto, når billetten udstedes. Alle beløb er i DKK. Danske kroner og incl. */}
							{/* moms. Der tages forbehold for prisfejl og udsolgte/udgåede varer. <br /> <br />{' '} */}
							{/* <span className="font-bold">Levering af billetter</span> */}
							{/* <br /> Museum Jorn sender billetter på e-mail, når der er modtaget en bestilling. Du får tilsendt */}
							{/* bekræftelse samt billetter via en vedhæftet PDF. Billetterne kan printes eller scannes via din */}
							{/* smartphone, når du ankommer til Museum Jorn. <br /> <br /> */}
							{/* <span className="font-bold">Gyldighed / Fortrydelsesret</span> <br /> Entrébilletters gyldighed er */}
							{/* noteret på billetten, som er tilsendt på e-mail i PDF. Studiebillet er kun gyldig ved fremvisning af */}
							{/* gyldigt studiekort. <br /> I henhold til ”Lov om forbrugeraftaler § 18 stk. 2 nr. 12” er billetter */}
							{/* købt på online ikke omfattet af fortrydelsesretten. Såfremt et arrangement aflyses refunderes */}
							{/* billetten naturligvis, og beløbet bliver automatisk tilbageført på den trukne betalingskonto. */}
							{/* Gebyrer refunderes ikke. <br /> <br /> <span className="font-bold">Mangler</span> */}
							{/* <br /> Museum Jorn påtager sig ikke noget ansvar for fejl eller forsinkelser i levering, som skyldes */}
							{/* tekniske problemer hos køber eller fejl i email oplyst af køber. Billetten er et ihændehaverbevis og */}
							{/* køber bærer selv ansvaret for eventuelt misbrug af onlinebilletter. I tilfælde af at kunden påviser */}
							{/* oprindelige mangler ved det leverede, er Museum Jorn forpligtet til, uden beregning for kunden, at */}
							{/* afhjælpe manglen, eller om nødvendigt at foretage omlevering af det leverede. Dog er kunden, i */}
							{/* tilfælde af at han påberåber sig mangler ved det leverede, forpligtet til at henvende sig til Museum */}
							{/* Jorn på info@museumjorn.dk. Såfremt Museum Jorn vurderer, at det ikke er muligt at afhjælpe manglen */}
							{/* eller foretage omlevering, betaler Museum Jorn skadeserstatning i henhold til bestemmelserne i */}
							{/* afsnittet vedrørende ”Ansvar og ansvarsbegrænsning” I tilfælde af påviste mangler ved det leverede, */}
							{/* kan kunden ikke fordre hel eller delvis ophævelse af købet, ligesom kunden ikke kan fordre */}
							{/* forholdsmæssigt afslag i købesummen. <br /> <br />{' '} */}
							{/* <span className="font-bold">Ansvar og ansvarsbegrænsning</span> */}
							{/* <br /> Dansk Rets almindelige regler finder anvendelse med den nedenfor anførte begrænsninger, */}
							{/* herunder at Museum Jorn ikke kan blive erstatningsansvarlig for kundens indirekte eller afledte tab */}
							{/* såsom transportomkostninger. Omfanget af Museum Jorns erstatningspligt er beløbsmæssigt begrænset, */}
							{/* således at Museum Jorn maksimalt kan være forpligtet til at betale et beløb til kunden svarende til */}
							{/* det vederlag kunden i henhold til aftalen skulle betale for den/de misligholdte ydelser. <br />{' '} */}
							{/* <br /> <span className="font-bold">Persondatapolitik</span> */}
							{/* <br /> For at du kan indgå aftale med os og handle på vores hjemmeside, har vi brug for følgende */}
							{/* oplysninger om dig. Du kan læse mere om vores persondatapolitik på vores website. <br /> <br /> */}
							{/* <span className="font-bold">Klagemuligheder - oversigt og links</span> */}
							{/* <br /> Har du en klage over en billet, købt hos os, kan der sendes en klage til os på */}
							{/* info@museumjorn.dk. <br /> Alternativt kan du kontakte Konkurrence- og Forbrugerstyrelsens Center */}
							{/* for Klageløsning <br /> Carl Jacobsens Vej 35 <br /> 2500 Valby <br /> Link: www.forbrug.dk <br />{' '} */}
							{/* eller hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens */}
							{/* online klageplatform. <br /> Platformen findes her: http://ec.europa.eu/consumers/odr/ <br />{' '} */}
							{/* Angiver du en klage her, skal du oplyse vores E-mail adresse: info@museumjorn.dk */}
						</DialogContentText>
					</DialogContent>
				</>
			)}
			<DialogActions>
				<Button onClick={handleClose}>Luk</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CartTermsDialog;
