import firebaseService from '@ameroservices-platform/shared/services/frontendFirebase';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import cookie from 'cookiejs';
import { getParameterByName } from '../utilities';
import FuseSplashScreen from '@ameroservices-platform/attraction-frontend/fuse/core/FuseSplashScreen';

class LaunchDarkly extends Component {
	state = {
		ldProvider: null
	};

	componentDidMount() {
		firebaseService.onAuthStateChanged(async userToken => {
			if (userToken) {
				const user = await userToken.getIdTokenResult();
				if (user && user.claims && user.claims.organisationId) {
					this.ldCheck(user).then(LDProvider => {
						if (LDProvider) {
							this.setState({ ldProvider: LDProvider });
						}
					});
				}
			}
		});
	}

	ldCheck = async authUser => {
		if (this.state.ldProvider) {
			return Promise.resolve();
		}
		console.log('authUser ', authUser);
		const user = {
			key: `frontend-org-${authUser.claims.organisationId}`,
			custom: {
				name: 'Generic Frontend User',
				organisationUid: authUser.claims.organisationId,
				anonymous: authUser.signInProvider === 'anonymous'
			}
		};
		const options = {};
		console.log('launchDarkly init with user and options: ', user, options);
		const feature = getParameterByName('feature');
		if (feature) {
			cookie.set('ld_feature', feature, { expires: 1 });
		}
		if (feature || cookie.get('ld_feature')) {
			const featureToSet = feature || cookie.get('ld_feature');
			console.log('value set on user ', featureToSet);
			user.custom[`feature-${featureToSet}`] = true;
		}
		const LDProvider = await asyncWithLDProvider({
			clientSideID: process.env.REACT_APP_LD_CLIENTSIDEID || '6096f35fceeceb0de5e990c7',
			user,
			options
		});
		return LDProvider;
	};

	render() {
		if (this.state.ldProvider) {
			const LDComponent = this.state.ldProvider;
			return <LDComponent>{this.props.children}</LDComponent>;
		}

		return <FuseSplashScreen />;
	}
}

function mapStateToProps({ auth }) {
	return {
		user: auth.user
	};
}

export default connect(mapStateToProps)(LaunchDarkly);
