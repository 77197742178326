const config = {
	applicationId: '1762c308-a18e-4714-9d5d-d73240d1ff92',
	clientToken: 'pub4160a708732e0dce0091e7476fd13eab',
	site: 'datadoghq.com',
	service: 'attraction-backend',
	env: 'REPLACE_BY_ENVIRONMENT',
	version: 'REPLACE_BY_VERSION',
	sampleRate: 100,
	trackInteractions: true,
};

export default config;
