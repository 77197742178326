export default {
	"DRAG_OR_CLICK_HERE": "Dra filer eller klikk her",
	"PROCESSING_ORDER": "Vi behandler bestillingen din, vennligst vent...",
	"PER_UNIT": "Pris per enhet",
	"REFUNDED": "Refundert",
	"CART_TERMS_OF_CONDITIONS_SUBSCRIPTION": "abonnementsvilkår og",
	"CART_AUTO_RENEWAL_ACCEPT": "Jeg ønsker å fornye medlemskapene automatisk før starten av hver periode",
	"ACCOUNT_SUBSCRIPTIONS_CHANGE_CARD": "Bytt kredittkort",
	"ACCOUNT_ACTIVATING": "Kontoen din blir aktivert, vennligst vent..",
	"ERROR_UNKNOWN": "En ukjent feil oppstod. Prøv igjen senere",
	"TYPE_EMAIL_UNDERNEATH": "Skriv inn e-postadressen din for å begynne å opprette medlemskap.",
	"I_WILL_RENEW_SUBSCRIPTION": "Jeg ønsker å fornye abonnementet i begynnelsen av hver periode",
	"NO_USER_FOUND_MESSAGE": "Du ser ikke ut til å ha en konto. Derfor må du først opprette kontoen din.",
	"THE_GIFT_CARD": "Gavekortet",
	"CHOOSE_TICKET_AMOUNT_BEFORE": "Velg antall billetter",
	"SEARCH_QUESTIONS": "Søkespørsmål...",
	"REMOVE_PRODUCT_FROM_CART": "Fjern fra handlekurven",
	"ORDER_NOTE_LABEL": "Merknader til bestillingen",
	"RENEW": "Fornye",
	"END": "Slutt",
	"ORDER_FAILED_TITLE": "Bestillingen mislyktes",
	"THE_BALANCE_IS": "er balansen",
	"MAIL": "Epostadresse",
	"CHOOSE_TICKETS": "Velg dine billetter",
	"CHOOSE_TICKETS_BEFORE_BUY": "Velg billetter først",
	"PRODUCTS_HAS_DIFFERENT_TIMESLOTS": "Utvalgte billetter har forskjellige tidsrom",
	"PHONE_INVALID_REMEMBER_COUNTRY_CODE": "Telefonnummeret er ugyldig. Hvis telefonnummeret ikke er fra Danmark, husk å legge til landskoden (+xx) før nummeret.",
	"EMAIL_ALREADY_EXISTS": "E-post eksisterer allerede. Logg på i stedet.",
	"AUTH_MISSING_EMAIL": "Mangler e-post",
	"ACCOUNT_ADD_TO_APPLE_WALLET": "Legg til Apple Wallet",
	"CHOOSE_DATE_BEFORE": "Velg dato først",
	"MORNING": "Morgen",
	"MAX_ORDER": "Du kan bestille maks",
	"AWAITING_RENEWAL": "Venter på fornyelse",
	"OK_BUTTON": "Ja",
	"BEFORE_MIDDAY": "Før klokken tolv",
	"MEMBERSHIP_RENEWAL_FROM": "Medlemskapet ditt vil bli fornyet",
	"TOTAL": "Total",
	"ERROR_PRODUCT": "Produktet på",
	"HOW_MANY": "Hvor mange skal du besøke?",
	"AUTH_EMAIL_ALREADY_IN_USE": "E-posten er allerede i bruk",
	"CHOOSE_TICKETS_BEFORE": "Vennligst velg antall billetter før",
	"IF_MAIL_EXIST_YOU_WILL_RECEIVE_EMAIL": "Hvis e-posten din finnes i systemet vårt, vil du snart motta en e-post som lar deg tilbakestille passordet ditt",
	"ACCOUNT_NOT_FOUND": "Kontoen din ble ikke funnet. Prøv igjen senere",
	"IF_YOU_CHOOSE_TO_REMOVE_THESE_PRODUCTS": "If you continue without selecting, the extras will not be added to your order.",
	"THANKS_FOR_ORDER": "takk for din bestilling",
	"TO_BE_ABLE_TO_PURCHASE_THESE_PRODUCTS": "For å kunne legge til ekstrautstyr til bestillingen din, må du velge billetter med samme tidsluke.",
	"YOUR_MEMBERSHIPS": "Dine medlemskap",
	"CART_SAME_NAMES_AS_BILLING": "Samme for- og etternavn som faktureringsinformasjon",
	"FAQ_HEADER": "Ofte stilte spørsmål",
	"ACCOUNT_NEEDED_EMAIL_CONFIRMATION": "Bekreftelse nødvendig",
	"TAX": "Avgift",
	"ALL_LOCATIONS_SELECTED": "Alle steder er valgt",
	"MAX_90_HEADER": "Maks 90 produkter",
	"ENTER_NEW_PASSWORD": "Skriv inn et nytt passord for brukeren med følgende e-post:",
	"SORRY": "Beklager",
	"LAST_NAME_MIN_2_CHAR": "Etternavn må inneholde minst 2 tegn",
	"CREATE_MEMBERSHIP": "Opprett medlemskap",
	"ACCOUNT_EXISTS": "Har du allerede en konto?",
	"CITY": "By",
	"REMOVE_FROM_CART": "Er du sikker på at du vil fjerne dette produktet fra handlekurven?",
	"YOU_CAN_LOGIN": "Du kan nå logge inn",
	"MEMBERSHIP": "Medlemskap",
	"CONTINUE_CREATING_SUBCRIPTION": "Fortsett medlemsregistrering her",
	"FULL_NAME": "Fullt navn",
	"CANCEL_BUTTON": "Nei",
	"PRODUCT": "Produkt",
	"CART_PAY_BUTTON": "BETALE",
	"READ_MORE": "Les mer",
	"LOGGED_IN_WITH_CORRECT_USER": "logget på med riktig bruker",
	"ERROR_EVENT": "Tiden på",
	"NEED_TO_BUY_MINIMUM_OF": "Du må kjøpe minimum",
	"CHOOSE_DATE": "Velg dato",
	"BUY_TICKET_BUTTON": "KJØP BILLETT",
	"TO_CREATE_MEMBERSHIP_YOU_NEED_A_USER": "For å opprette et medlemskap må du ha opprettet en konto hos oss. Start derfor med å skrive inn e-postadressen din slik at vi kan sjekke om du allerede er registrert hos oss.",
	"PRICE_EA": "PRIS PR. ENHET",
	"TICKETS_LEFT": "billetter igjen",
	"NEWSLETTER_SIGNUP": "Registrer deg for å få nyhetsbrev",
	"ACCOUNT_ACTIVATED": "Kontoen din har blitt aktivert",
	"CART_CARDHOLDER": "Kortholder",
	"ALL_CATEGORIES_SELECTED": "Alle kategorier er valgt",
	"DEACTIVATE_RENEWAL_EXPLAINER": "Ved å deaktivere fornyelse vil ikke abonnementet bli fornyet. Du må betale manuelt.",
	"PAYMENT": "innbetaling",
	"VALUE": "Verdi",
	"NO_EVENTS": "Det er ingen treff til søkekriteriene dine",
	"YOU_HAVE_ALREADY_ADDED_THE_MAX": "Du har allerede lagt til maksimumsbeløpet",
	"PASSWORD_LENGTH": "Passordet må inneholde minst 6 tegn",
	"PHONE": "Telefon",
	"SELECT": "Plukke ut",
	"UPDATE_PROFILE": "Oppdater profil",
	"ADDRESS": "Adresse",
	"CONTINUE": "Fortsette",
	"CORRECT_PASSWORD": "Riktig passord, du blir nå logget på.",
	"TO": "Til",
	"ORDER_FAILED_DESCRIPTION": "Bestillingen ble ikke fullført og pengene er ikke trukket",
	"OF_THIS_PRODUCT": "av dette produktet",
	"START": "Start",
	"RESEND_CONFIRMATION_EMAIL": "Send bekreftelses-epost på nytt",
	"APPLY": "Søke om",
	"GIFT_CARD": "Gavekort",
	"PHONE_NR_MIN_2_CHAR_INT": "Telefonnummeret må være mellom 6-20 tegn",
	"GUEST": "Gjest",
	"PAY": "Betale",
	"VISIT_TIME": "Når ønsker du å komme?",
	"CONTINUE_WITHOUT_UPSELL": "Fortsett uten ekstrautstyr",
	"NO_IMAGE": "Ingen bilder er valgt",
	"PASSWORD": "Passord",
	"NOT_FOUND": "Ikke funnet",
	"PHONE_NUMERIC": "Telefonnummeret må være numerisk",
	"WHICH_LOCATION": "Hvilket sted ønsker du å besøke?",
	"SUBSCRIPTION_VALID_FROM": "Abonnementet er gyldig fra kl",
	"PER_PERIOD": "Per periode",
	"MAIL_SENT": "E-post har blitt sendt",
	"APPLY_GIFT_CARD_CODE": "Bruk gavekort",
	"EMAIL_IS_CONFIRMED": "E-posten din er bekreftet!",
	"DEACTIVATE_RENEWAL": "Deaktiver automatisk fornyelse",
	"I_HAVE_READ_AND_ACCEPT": "Jeg har lest og godtar",
	"AFTERNOON": "Ettermiddag",
	"LOGOUT": "Logg ut",
	"EXPIRES_ON": "Går ut på dato den",
	"CHOOSE_QUANTITY": "Velg billetter",
	"ADD_CREDITCARD": "Legg til kredittkort",
	"CART_ERROR_FIELDS": "Feil",
	"LOGIN_AGAIN": "Logg på igjen",
	"SEND_CONFIRMATION_MAIL": "Send bekreftelsesmail",
	"AUTH_INVALID_EMAIL": "E-posten er ugyldig",
	"ERROR_RESETTING_PASSWORD": "Det har oppstått en feil under tilbakestilling av passordet ditt, prøv igjen.",
	"WE_ALREADY_SENT_EMAIL": "Vi har allerede sendt deg en e-post.",
	"CITY_MIN_2_CHAR": "Bynavn må inneholde minst 1 tegn",
	"DATO": "Dato",
	"AWAITING_PAYMENT": "Venter på betaling",
	"AUTIMATIC_RENEWAL": "Medlemskapet ditt er nå satt til automatisk fornyelse",
	"GO_BACK_AND_CHANGE": "Gå tilbake og fjern billetter med forskjellige tidsluker.",
	"LOADING_EVENT": "Laster inn hendelsen ...",
	"VISIT_DATE": "Når ønsker du å besøke?",
	"IS_CREATING": "skaper",
	"TRY_AGAIN": "Prøv igjen",
	"THE_SUBCRIPTION": "abonnementet",
	"POSTAL_CODE_NUMERIC": "Postnummer må være numerisk",
	"PRICE": "Pris",
	"CHANGE_IMAGE": "Bytt bilde",
	"SEND_CONFIRMATION_EMAIL": "Send bekreftelses-e-post",
	"CART_EMAIL_ALREADY_CREATED_NOT_CONFIRMED": "Du er allerede registrert, men e-posten din er ikke bekreftet ennå",
	"CONTACT_DETAILS": "Skriv inn kontaktdetaljer",
	"MORE_DATES": "Se flere datoer",
	"DOES_NOT_EXIST_OR_EXPIRED": "eksisterer ikke eller har utløpt",
	"EXISTING_ACCOUNT": "Eksisterende konto",
	"AUTH_USER_DISABLED": "Brukeren er suspendert",
	"ACCOUNT_ADD_TO_GOOGLE_WALLET": "Legg til i Google Wallet",
	"FIRST_NAME": "Fornavn",
	"ACCOUNT_INFO": "Kontoinformasjon",
	"AUTOMATIC_RENEWAL_EXPLAINER": "Ved å aktivere fornyelse vil medlemskapet fornyes automatisk ved begynnelsen av neste periode",
	"CONFIRM": "Bekrefte",
	"AUTH_WRONG_PASSWORD": "Feil passord",
	"EMAIL_CONFIRMED": "E-post bekreftet",
	"ORDER_FAILED_DESCRIPTION_2": "Vennligst prøv igjen snart eller kontakt oss hvis du fortsetter å oppleve dette",
	"QUANTITY": "Mengde",
	"PRODUCTS_IN_SUBSCRIPTION": "Produkter i abonnementet",
	"ACTIVATE_ACCOUNT": "Aktiver kontoen din",
	"SIGN_UP_BUTTON": "Melde deg på",
	"EMAIL_MUST_CONFIRMED_EXPLAINER": "Før du kan opprette medlemskap, må vi bekrefte e-postadressen din",
	"POSTAL_CODE_LENGTH_INT": "Postnummer må inneholde 3-8 tegn.",
	"UNKOWN_EVENT": "Ukjent hendelse",
	"CONFIRM_EMAIL": "Bekreft epost",
	"INACTIVE": "Inaktiv",
	"TO_ACTIVE_ACCOUNT": "For å aktivere kontoen din må du bekrefte eierskap til e-posten.",
	"VALIDITY_PERIOD": "Gyldighetsperiode",
	"AUTH_WEAK_PASSWORD": "Passordet er for svakt. Bruk et passord med mer enn 8 tegn",
	"LOADING": "Laster inn...",
	"NIGHT": "Natt",
	"YOUR_ORDERS": "Dine ordre",
	"CUSTOMER": "Kunde",
	"CART_BILLING_INFORMATION": "Faktureringsinformasjon",
	"TERMS_OF_CONDITIONS": "vilkårene",
	"SELECT_DATE": "Velg datoen du ønsker å bestille en aktivitet for",
	"REMOVE_PRODUCT_NOT_ALLOWED": "Det er ikke tillatt å fjerne dette produktet fra handlekurven",
	"SEND_CODE": "Send kode",
	"COUNTRY": "Land *",
	"YES": "Ja",
	"CODE_IS_SENT_TO": "Koden er sendt til",
	"SOLD_OUT": "Utsolgt",
	"MEMBERSHIP_TYPE_DOES_NOT_EXIST": "Den valgte medlemskapstypen eksisterer ikke, prøv en annen type.",
	"FIRST_NAMEFIRST_NAME_MIN_2_CHAR": "Fornavn må inneholde minst 2 tegn",
	"CARD": "Kort",
	"CHECK_SPAM": "Hvis du ikke har mottatt e-postbekreftelsen innen noen få minutter, sjekk søppelpostmappen din.",
	"PHONE_LENGTH": "Telefonnummeret må bestå av minst 8–11 tegn",
	"YOU_CAN_MIN_BUY": "Du må kjøpe minst",
	"YOU_HAVE_ALREADY_REMOVED_THE_MIN": "Du har allerede fjernet minimumsbeløpet",
	"ERROR_EMAIL_ALREADY_IN_USE": "E-postadressen er allerede i bruk. Prøv en annen e-post",
	"SIGN_UP_STATUS": "Registrere seg..",
	"AUTH_USER_NOT_FOUND": "Brukeren ble ikke funnet",
	"APPLY_DISCOUNT_CODE": "Bruk kampanjekode",
	"CODE": "Kode",
	"LAST_NAME": "Etternavn",
	"YOUR_CART": "Handlevognen din",
	"CART_EMAIL_NOT_CREATED_BEFORE": "Du er ikke opprettet, og kan fortsette",
	"UPLOADING_PROFILE_PICTURE": "Laster opp profilbilde",
	"PHONE_NR_MIN_2_CHAR": "Telefonnummeret må være mellom 8-11 tegn",
	"EMAIL_SENT": "Vi har nettopp sendt deg en e-post, som du må bekrefte ved å klikke på knappen.",
	"FORGOT_PASSWORD": "GLEMT PASSORD",
	"EVENT_TIME": "Tidspunkt for arrangementet",
	"EDIT_UPSELL_OPTIONS": "Rediger flere kjøp",
	"CHANGING_MEMBERSHIP": "Vi endrer medlemskapet ditt, vennligst vent...",
	"ENTER_PASSWORD": "Skriv inn et passord for kontoen din",
	"FIRST_NAME_MIN_2_CHAR": "Fornavn må inneholde minst 2 tegn",
	"HI": "Hei",
	"I_AM_CONFIRMING": "Jeg bekrefter",
	"MEMBERSHIP_NUMBER": "Abonnementsnummer",
	"CONFIRMATION_MAIL_TO": "En bekreftelsesmail vil bli sendt til",
	"CANCELLED": "avbrutt",
	"TICKET_NUM": "Billettnummer",
	"UPSELL_TITLE": "Ekstrautstyr for bestillingen din",
	"TICKET": "Billett",
	"CHOOSE_CATEGORY": "Choose category",
	"FROM": "Fra",
	"CHARGE_IS_SEVEN_DAYS_BEFORE": "* Merk at ved automatisk fornyelse av medlemskap vil belastningen skje 7 dager før utløpsdatoen",
	"APPLY_DISCOUNT_OR_GIFT_CARD_CODE": "Bruk kampanjekode eller gavekort",
	"GO_TO_PAYMENT": "Gå til utsjekk",
	"EMAIL_CONFIRMNIG": "Bekrefter e-post...",
	"CART_CARDHOLDER_FOR": "til",
	"YOUR_INFORMATIONS": "Din informasjon",
	"FORGOT_PASSWORD_QUESTIONMARK": "Glemt passord?",
	"ACCOUNT_EMAIL_NOT_CONFIRMED_YET": "E-posten din er ikke bekreftet ennå, sjekk e-posten din og klikk på lenken i e-posten.",
	"ACTIVATE": "Aktiver",
	"PHONE_NUMBER": "Telefonnummer",
	"SEARCH": "Søk",
	"CART_CONTINUE": "Fortsette",
	"ON_THE_GIFT_CARD": "På gavekortet",
	"LOCATIONS": "Steder",
	"BUY": "Kjøpe",
	"CART_CHECKING_EMAIL": "Sjekker e-post...",
	"SEND_CONTROL_CODE": "Send kontrollkode",
	"ISSUED_TO": "Utstedt til",
	"ADDRESS_MIN_2_CHAR": "Adressen må inneholde minst 2 tegn",
	"DEACTIVATE": "Deaktiver",
	"DOES_NOT_EXIST": "The page does not exist",
	"MY_PAGE": "Min side",
	"BUY_TICKET_BUTTON_FREE": "Reserver billetter",
	"YOUR_EMAIL_ADDRESS": "Your email",
	"REMOVE": "Fjerne",
	"EMAIL_BEING_CONFIRMED": "E-posten din blir bekreftet, vennligst vent..",
	"CART_EMAIL_ALREADY_CREATED": "You are already registered, enter your password below",
	"LINK_IS_DEAD": "Linken du har fulgt ser ut til å være ugyldig..",
	"CONFIRM_EMAIL_ERROR": "E-postene stemmer ikke",
	"SELECT_FROM_LIST": "Velg fra listen",
	"NO_TIMES_AVAILABLE": "Ingen tider tilgjengelig",
	"BUY_ACTIVITIES": "Kjøp ekstra aktiviteter",
	"AUTH_ERROR_TITLE": "Feil",
	"LOGIN": "Logg Inn",
	"CATEGORIES": "Kategorier",
	"CONFIRMATION": "Bekreftelse",
	"HAVE_NO_USER": "Har du ingen bruker?",
	"RESET_PASSWORD": "tilbakestille passord",
	"ACCOUNT_YOUR_ACCOUNT": "Kontoen din",
	"CREATE_USER": "Opprett bruker",
	"WELCOME": "Velkommen!",
	"ADD_ANOTHER_GIFT_CARD": "Legg til et nytt gavekort eller kampanjekode",
	"ACCOUNT_TAB_SUBSCRIPTIONS": "Medlemskap",
	"NOT_VALID_EMAIL": "Ikke gyldig e-post",
	"CART_ERROR_MISSING_FIELDS": "Mangler fullføring",
	"THANKS_FOR_DETAILS": "Takk for detaljene dine",
	"ARE_YOU_NOT": "Er du ikke",
	"AMOUNT_PEOPLE": "Hvor mange mennesker er dere?",
	"CHECK_GIFT_CARD": "Sjekk saldoen på gavekortet ditt",
	"SELECTED_TIME": "Valgt tid:",
	"YOUR_TICKETS": "Dine billetter",
	"ALREADY_EXISTS": "E-posten finnes allerede i systemet vårt, men er ikke aktivert for nettbruk",
	"EMAIL": "E-post",
	"EMAIL_DOES_NOT_EXIST": "eksisterer ikke",
	"CREATION_OF_MEMBERSHIP": "Opprettelse av medlemskap",
	"IN_THE_FIELD": "i feltet for å bekrefte",
	"CARD_NUM": "Kortnummer",
	"HOW_MANY_ACTIVITIES": "Velg antall medlemskap du ønsker å bestille en aktivitet for",
	"MAX_90": "Du kan ikke ha mer enn 90 produkter i handlekurven.",
	"ERROR": "En feil oppstod",
	"TOTAL_PRICE": "Totalpris",
	"POSTAL_CODE": "Postnummer",
	"RELEASE_FILES_HERE": "Slipp filer her",
	"ACTIVE": "Aktiv",
	"SUBTOTAL": "Delsum",
	"EMAIL_ERROR": "Dette er ikke en gyldig e-post",
	"COMPLETED": "Fullført",
	"YOUR_MEMBERSHIP_CARDS": "Dine medlemskort",
	"EXPIRES_SOON": "Utløper snart",
	"RENEWAL_WHEN_PAYMENT_CHARGED": "In automatic renewal, the charge will occur 7 days before the expiration date. In manual renewal, the charge will occur on the day you renew.",
	"AUTIMATIC_RENEWAL_EXPLAINER": "Ved å aktivere fornyelse vil medlemskapet fornyes automatisk ved begynnelsen av neste periode",
	"APP_TITLE": "listEventGroupDateSelector",
	"ACCOUNT_TAB_INFORMATION": "Informasjon",
	"PASSWORD_CHANGED": "passord endret",
	"CHANGING_PASSWORD": "Angir passordet ditt - vennligst vent..",
	"GO_BACK": "Gå tilbake til handlekurven din",
	"BUY_TICKET": "Kjøp billett",
	"ORDER_NOTE_PLACEHOLDER": "Skriv notatene dine her",
	"WRITE": "skrive",
	"CITY_LENGTH": "By må bestå av minst 2 tegn",
	"PASSWORD_CHANGED_SUCCESSFULLY": "ditt passord har blitt endret",
	"CHOOSE_LOCATION": "Velg plassering",
	"THIS_DAY": "billetter denne dagen.",
	"AUTH_OPERATION_NOT_ALLOWED": "Ukjent feil",
	"LOGIN_INSTEAD": "Logge på i stedet?",
	"ACTIVATE_RENEWAL": "Aktiver automatisk fornyelse",
	"CONFIRM_PASSWORD": "Bekreft passord",
	"SUBSCRIPTION_GIFT_CARD": "Abonnement gavekort",
	"ACTIVE_ACCOUNT_BY": "Dette kan gjøres ved å følge lenken under, hvoretter du vil motta en e-post.",
	"BUY_TICKET_FREE": "Reserver billett",
	"TRY_NEW_CARD": "Prøv et nytt kort",
	"AUTH_PASSWORDS_DO_NOT_MATCH": "Passordene stemmer ikke",
	"YOU_MUST_CONFIRM_EMAIL": "Du må bekrefte e-postadressen din for å få en konto.",
	"AMOUNT": "Beløp",
	"CREATE_ACCOUNT": "Opprett konto",
	"CONFIRMATION_MAIL_TO_YOU": "En bekreftelsesmail vil bli sendt til deg",
	"CHOOSE_AMOUNT_PEOPLE": "Choose amount",
	"MISSING_ACCOUNT": "Manglende konto",
	"YOU_CAN_CLOSE_THIS_WINDOW": "Du kan nå lukke dette vinduet",
	"SUBSCRIPTION_VALID_FROM_FIRST_TIME": "Abonnementet er gyldig fra første gang du bruker det",
	"FINISH": "Bli ferdig",
	"ALL_DATES_SELECTED": "Alle datoer er valgt",
	"CONFIRM_MAIL_TO_SHOW_ORDERS": "Før vi kan vise din tidligere {type} må du bekrefte e-posten din.",
	"PLEASE_WAIT": "Vennligst vent",
	"POSTAL_CODE_LENGTH": "Postnummer må inneholde 3-4 tegn.",
	"EMAIL_MUST_BE_CONFIRMED": "E-posten din må bekreftes",
	"YOU_NEED_TO_SELECT_TIME_OR_PRODUCTS": "Du må velge et tidspunkt eller antall billetter.",
	"SELECT_ACTIVITIES": "Velg aktiviteter",
	"CHOOSE_TIME": "Velg tid",
	"EVENING": "Kveld",
	"MEMBERSHIP_IS_CREATED_ON_THIS_CUSTOMER": "Medlemskapet opprettes på denne kunden",
	"FAQ_SUBTITLE": "Vi er her for å hjelpe",
	"ORDER": "Rekkefølge",
	"CHECK_CODE": "Sjekk gavekort",
	"YOU_CAN_MAX_BUY": "You can buy max",
	"CANCEL": "Avbryt",
	"NO_QUESTIONS": "Ingen spørsmål er funnet",
	"ENTER_DETAILS": "Skriv inn informasjonen din for å opprette en konto.",
	"PHONE_NR": "Telefonnummer",
}