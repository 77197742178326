import { SnackbarProvider } from 'notistack';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { LocalizationProvider } from '@mui/lab';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment-timezone';
import 'moment/locale/da';
import datadogService from '@ameroservices-platform/shared/services/datadog';
import BrowserRouter from '@ameroservices-platform/attraction-frontend/fuse/core/BrowserRouter';
import FuseAuthorization from '@ameroservices-platform/attraction-frontend/fuse/core/FuseAuthorization';
import FuseTheme from '@ameroservices-platform/attraction-frontend/fuse/core/FuseTheme';
import FuseLayout from '@ameroservices-platform/attraction-frontend/fuse/core/FuseLayout';
import ErrorBoundary from '@ameroservices-platform/attraction-frontend/app/ErrorBoundary';
import FrontendInitialization
	from '@ameroservices-platform/attraction-frontend/app/frontendInitialization/FrontendInitialization';
import TagManagerInit from '@ameroservices-platform/attraction-frontend/app/tagManager/TagManagerInit';
import LaunchDarkly from '@ameroservices-platform/attraction-frontend/app/launchDarkly/LaunchDarkly';
import {getParameterByName} from './utilities'

if (getParameterByName('language')) {
	localStorage.setItem('language', JSON.stringify({ isoCode: getParameterByName('language') }));
}
const language = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : { isoCode: 'da' };
if (!language || !language.isoCode || language.isoCode === 'da') {
	moment.updateLocale('da', {
		months: [
			'Januar',
			'Februar',
			'Marts',
			'April',
			'Maj',
			'Juni',
			'Juli',
			'August',
			'September',
			'Oktober',
			'November',
			'December',
		],
		monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
		weekdays: ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
		weekdaysShort: ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør'],
		weekdaysMin: ['Sø', 'Ma', 'Ti', 'On', 'To', 'Fr', 'Lø'],
		week: {
			dow: 1,
		},
	});
} else {
	moment.updateLocale(language.isoCode, {
		week: {
			dow: 1,
		},
	});
}
datadogService.init();

const App = () => {
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<ErrorBoundary>
				<Auth>
					<LaunchDarkly>
						<TagManagerInit>
							<BrowserRouter>
								<FrontendInitialization>
									<FuseAuthorization>
										<FuseTheme>
											<SnackbarProvider
												maxSnack={5}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
												classes={{
													containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
												}}
											>
												<FuseLayout />
											</SnackbarProvider>
										</FuseTheme>
									</FuseAuthorization>
								</FrontendInitialization>
							</BrowserRouter>
						</TagManagerInit>
					</LaunchDarkly>
				</Auth>
			</ErrorBoundary>
		</LocalizationProvider>
	);
};

export default withAppProviders(App)();
