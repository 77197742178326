/* eslint import/no-extraneous-dependencies: off */
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/performance';
import 'firebase/compat/storage';
import { Observable } from 'rxjs';
import moment from 'moment-timezone';
import config from './firebaseServiceConfig';
import _ from 'lodash';

const rerouteFunctions = {
	organisationBudget: 'organisationBudgetBudgetHandler',
	organisationFinanceDepartment: 'organisationFinanceDepartmentFinanceDepartmentHandler',
	organisationStatistic: 'organisationStatisticStatisticHandler',
	organisationEventGroupEventScheduler: 'organisationEventGroupEventSchedulerEventSchedulerHandler',
	organisationEventGroupContentDefaultTemplate: 'organisationOrganisationHandler',
	organisationEventGroup: 'organisationEventGroupEventGroupHandler',
	organisationLocation: 'organisationLocationLocationHandler',
	organisationCustomerOrder: 'organisationCustomerOrderOrderHandler',
	organisationCustomerSettlementAccount: 'organisationCustomerSettlementAccountSettlementAccountHandler',
	organisationCustomerCart: 'organisationCustomerCartCartHandler',
	organisationCustomerSubscriptions: 'organisationCustomerSubscriptionsSubscriptionsHandler',
	organisationOrganisationUser: 'organisationOrganisationUserOrganisationUserHandler',
	user: 'userUserHandler',
	contentLanguages: 'contentLanguagesLanguagesHandler',
	contentLayoutElements: 'contentLayoutElementsLayoutElementsHandler',
	contentPages: 'contentPagesPagesHandler',
	contentTemplates: 'contentTemplatesTemplatesHandler',
	contentFlexposSettings: 'contentFlexposSettingsFlexposSettingsHandler',
	content: 'contentContentHandler',
	organisationFlexposLink: 'organisationFlexposLinkFlexposLinkHandler',
	organisationCustomerTickets: 'organisationCustomerTicketsTicketsHandler',
	organisationProduct: 'organisationProductProductHandler',
	bigQuery: 'bigQueryBigQueryHandler',
	flexpos: 'flexposFlexposHandler',
	organisationGetStock: 'organisationOrganisationHandler',
	organisationCreateOrganisation: 'organisationOrganisationHandler',
	organisationSubscriptionTypeGetSubscriptionTypeCurrentEvent: 'organisationOrganisationHandler',
	organisationSubscriptionTypeCreateSubscriptionType: 'organisationOrganisationHandler',
	organisationSubscriptionTypeUpdateSubscriptionType: 'organisationOrganisationHandler',
	organisationSubscriptionTypeRegenerateSubscriptionTypeSummary: 'organisationOrganisationHandler',
	organisationMailTemplatesUpdateMailTemplate: 'organisationOrganisationHandler',
	organisationSmsTemplatesUpdateSmsTemplate: 'organisationOrganisationHandler',
	organisationIncrementalNumbersUpdateIncrementalNumber: 'organisationOrganisationHandler',
	organisationOrganisationHandlerPing: 'organisationOrganisationHandler',
	organisationCustomerCreateCustomer: 'organisationOrganisationHandler',
	organisationCustomerCreatePendingPayoutPos: 'organisationOrganisationHandler',
	organisationCustomerUpdateCustomerEmail: 'organisationOrganisationHandler',
	organisationGetOrganisationModules: 'organisationOrganisationHandler',
	organisationCustomerResyncCustomers: 'organisationOrganisationHandler',
	organisationCustomerUpdateCustomer: 'organisationOrganisationHandler',
	organisationCustomerCheckCustomerEmail: 'organisationOrganisationHandler',
	organisationCustomerSendConfirmationMail: 'organisationOrganisationHandler',
	organisationCustomerActivateUser: 'organisationOrganisationHandler',
	organisationCustomerSendResetCustomerPassword: 'organisationOrganisationHandler',
	organisationCustomerImpersonateCustomer: 'organisationOrganisationHandler',
	organisationGetEconomyAccounts: 'organisationOrganisationHandler',
	organisationTicketTypeUpdateTicketType: 'organisationOrganisationHandler',
	organisationList: 'organisationListListHandler',
	organisationImportJob: 'organisationImportJobImportJobHandler',
	organisationExportJob: 'organisationExportJobExportJobHandler',
	organisationEventAttributeGroup: 'organisationEventAttributeGroupEventAttributeGroupHandler',
	sysAdmin: 'sysAdminSysAdminHandler',
	templates: 'templatesTemplatesHandler',
	elementTemplates: 'elementTemplatesElementTemplatesHandler',
	googleCloudLogging: 'googleCloudLoggingGoogleCloudLoggingHandler',
	organisationInvoice: 'organisationInvoiceInvoiceHandler',
	organisationFollowProduct: 'organisationFollowProductFollowProductHandler',
	organisationElasticSearchSearch: 'organisationOrganisationHandler',
	organisationBankFile: 'organisationBankFileBankFileHandler',
	organisationUpdateBankPayoutsInfo: 'organisationOrganisationHandler',
	integration: 'integrationIntegrationHandler',
	organisationExternalBooking: 'organisationExternalBookingExternalBookingHandler',
	organisationGetEconomyJournals: 'organisationOrganisationHandler',
	globalData: 'globalDataGlobalHandler',
	organisationTicketTypeToggleTicketTypeDefaultRule: 'organisationOrganisationHandler',
	organisationCustomerSendSMS: 'organisationOrganisationHandler',
	organisationCustomerAnonymizeCustomer: 'organisationOrganisationHandler',
	organisationElasticSearchInlineEdit: 'organisationOrganisationHandler',
	organisationDiscountCodeType: 'organisationDiscountCodeTypeDiscountCodeTypeHandler',
	organisationRecurringScheduledJob: 'organisationRecurringScheduledJobRecurringScheduledJobHandler'
};

class FirebaseService {
	init(success, firebaseOverwrite = null, type, alertError = true) {
		this.alertError = alertError;
		this.organisationType = type;
		this.forceOnAuthStateChangedObservers = [];
		this.forceOnAuthStateChangedObservable = new Observable((observer) => {
			this.forceOnAuthStateChangedObservers.push(observer);
		});

		const isLocalEmulator = window.location.hostname === 'localhost';
		if (firebase.apps.length) {
			return;
		}
		fetch('/__/firebase/init.json').then(async (response) => {
			const config = await response.json();

			if (isLocalEmulator) config.appId = '1:747189088654:web:40a251705b65ba7a61b4df';
			firebase.initializeApp(config);

			// firebase.initializeApp(config);
			if (firebaseOverwrite) {
				this.db = firebaseOverwrite.firestore || null;
				this.auth = firebaseOverwrite.auth || null;
				this.functions = firebaseOverwrite.functions || null;
				this.storage = firebaseOverwrite.storage || null;

				this.functions.useFunctionsEmulator('http://localhost:5001');
			} else {
				this.db = firebase.firestore();
				this.auth = firebase.auth();
				this.functions = firebase.app().functions('europe-west3');
				this.performance = firebase.performance();
				this.storage = firebase.storage();

				this.auth.onAuthStateChanged((auth) => {
					if (this.auth.currentUser)
						this.auth.currentUser.getIdTokenResult().then((idTokenResult) => {
							const { organisationIds, signedSearchKeys } = idTokenResult.claims;
							// const { role } = idTokenResult.claims;
							console.log('#1', organisationIds);
							console.log('#2', this.organisationType);
							this.currentOrganisationId = organisationIds?.[this.organisationType] || null;
							// this.signedSearchKey = signedSearchKeys[this.organisationType] || null;
						});
					else {
						this.currentOrganisationId = null;
					}
				});
				if (isLocalEmulator) {
					this.db.settings({
						host: '127.0.0.1:8080',
						ssl: false,
					});
					this.functions.useFunctionsEmulator('http://localhost:5001');
				}
			}
			console.log('auth init', this.auth);
			success(true);
		});
	}

	initOld(success) {
		if (Object.entries(config).length === 0 && config.constructor === Object) {
			if (process.env.NODE_ENV === 'development') {
				console.warn(
					'Missing Firebase Configuration at src/app/services/firebaseService/firebaseServiceConfig.js'
				);
			}
			success(false);
			return;
		}

		if (firebase.apps.length) {
			return;
		}
		firebase.initializeApp(config);
		this.db = firebase.database();
		this.auth = firebase.auth();
		success(true);
	}

	getUserData = async (userId) => {
		if (!firebase.apps.length) {
			return false;
		}
		// return new Promise((resolve, reject) => {
		const docRef = this.db.collection('users').doc(userId);

		try {
			const userDoc = await docRef.get();
			const userDocData = userDoc.exists ? userDoc.data() : {};
			// if (!userDoc.exists) console.log('USER: No such document!');
			// if (userDoc.exists) console.log('USER: Document data:', userDocData);

			const idTokenResult = await this.auth.currentUser.getIdTokenResult();

			return {
				...userDocData,
				claims: idTokenResult.claims || {},
				role: idTokenResult.claims.role ? [idTokenResult.claims.role] : ['user'],
			};
		} catch (e) {
			if (window.location.hostname === 'localhost') {
				// eslint-disable-next-line no-alert
				alert('Kan ikke hente bruger data - Firebase emulatoren er formentlig slukket.');
			}
			return {};
		}
	};

	updateUserData = (user) => {
		if (!firebase.apps.length) {
			return false;
		}
		return this.db.collection('users').doc(user.uid).set(user);
	};

	onAuthStateChanged = (callback) => {
		if (!this.auth) {
			console.log('onAuthStateChanged no auth object!?');
			return;
		}
		this.forceOnAuthStateChangedObservable.subscribe({
			next(authObj) {
				callback(authObj);
			},
		});
		this.auth.onAuthStateChanged(callback);
	};

	signOut = () => {
		if (!this.auth) {
			return;
		}
		this.auth.signOut();
	};

	sendEmailVerification = async () => {
		await this.auth.currentUser.sendEmailVerification();
		return true;
	};

	requestAuthPromoteToAmeroAdmin = async () => {
		await this.functions.httpsCallable('authPromoteToAmeroAdmin')();
		return true;
	};

	requestConfig = async () => {
		const response = await this.callFunctionByName('organisationGetOrganisationModules');
		const modules = response.data && response.data.modules ? response.data.modules : {};
		const signedSearchKey = response.data && response.data.signedSearchKey ? response.data.signedSearchKey : '';
		this.setOrganisationModules(modules);
		if (signedSearchKey) {
			this.setSignedSearchKey(signedSearchKey);
		}
		this.setOrganisationModules(modules);
		this.setOrganisationSettings(response.data);
		return true;
	};

	refreshToken = async () => {
		await this.auth.currentUser.getIdToken(true);
		this.forceOnAuthStateChangedObservers.forEach((forceOnAuthStateChangedObserver) => {
			forceOnAuthStateChangedObserver.next(this.auth.currentUser);
		});
		return true;
	};

	getUsers = async (callback) => {
		await this.getOrganisationRootDB()
			.collection('users')
			.onSnapshot((querySnapshot) => {
				const u = [];
				querySnapshot.forEach((doc) => {
					const docData = doc.data();
					u.push({
						...docData,
						id: doc.id,
					});
				});
				callback(u);
			});
	};

	getRootDB = () => {
		return this.db;
	};

	setOrganisationModules = (modules) => {
		this.organisationModules = modules;
	};

	getOrganisationModule = (key) => {
		if (!this.organisationModules) {
			return false;
		}
		return this.organisationModules[key];
	};

	getOrganisationModules = () => {
		return this.organisationModules;
	};

	setOrganisationSettings = (settings) => {
		this.organisationSettings = settings;
	};

	getOrganisationSetting = (key) => {
		if (!this.organisationSettings) {
			return false;
		}
		return this.organisationSettings[key];
	};

	getOrganisationSettings = () => {
		return this.organisationSettings;
	};

	setOrganisationId = (organisationId) => {
		this.currentOrganisationId = organisationId;
	};

	setTenantId = (tenantId) => {
		// console.log(tenantId);
		this.auth.tenantId = tenantId;
	};

	setSignedSearchKey = (signedSearchKey) => {
		this.signedSearchKey = signedSearchKey || null;
	};

	getOrganisationId = () => {
		return this.currentOrganisationId;
	};

	getOrganisationType = () => {
		return this.organisationType;
	};

	getSignedSearchKey = () => {
		return this.signedSearchKey;
	};

	getFlexposDataRootDb = () => {
		const organisationId = this.getOrganisationId() || 'qNsa9duXAItXd6l83Px9';
		return this.db.collection('flexposData').doc(organisationId);
	};

	getFlexposUserResources = () => {
		const organisationId = this.getOrganisationId() || 'qNsa9duXAItXd6l83Px9';
		return this.db.collection('flexposUserResources').doc(organisationId);
	};

	getOrganisationRootDB = () => {
		const organisationId = this.getOrganisationId() || 'qNsa9duXAItXd6l83Px9';
		return this.db.collection('organisations').doc(organisationId);
	};

	getContentRootDB = () => {
		const organisationId = this.getOrganisationId();
		return this.db.collection('content').doc(organisationId);
	};

	getAuditLogRootDB = () => {
		const organisationId = this.getOrganisationId() || 'qNsa9duXAItXd6l83Px9';
		return this.db.collection('auditLogs').doc(organisationId);
	};

	getFunctions = () => {
		return this.functions;
	};

	callFunctionByName = async (functionName, data, throwError, tries) => {
		return this._callFunctionByNameHelper(
			functionName,
			data,
			throwError ? throwError : !this.alertError,
			tries === null || tries === undefined ? 0 : tries,
			500,
			moment()
		);
	};

	_waitFor = (ms) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	};

	printObject = (key, obj, index = 0) => {
		if (obj instanceof Array) {
			console.error('[ERROR-MSG] ' + _.range(0,index).map(() => ``).join("-") + key + ': ' + obj.toString());
		} else if (obj instanceof Object) {
			console.error('[ERROR-MSG] ' + _.range(0,index).map(() => ``).join("-") + key + ': ');
			Object.entries(obj).forEach(([key2, value]) => {
				this.printObject(key2, value, index+1);
			});
		} else {
			console.error('[ERROR-MSG] ' + _.range(0,index).map(() => ``).join("-") + key + ': ' + obj);
		}
	};

	_callFunctionByNameHelper = async (functionName, data, throwError, tries, tick, started) => {
		try {
			const reroute = Object.keys(rerouteFunctions).find((r) => functionName.startsWith(r));
			if (reroute && rerouteFunctions[reroute]) {
				const callable = this.functions.httpsCallable(rerouteFunctions[reroute]);
				return await callable({
					functionToUse: functionName,
					data: {
						...data,
						_organisationUid: this.getOrganisationId(),
						_organisationType: this.organisationType,
					},
				});
			}
			const callable = this.functions.httpsCallable(functionName);
			return await callable({
				...data,
				_organisationUid: this.getOrganisationId(),
				_organisationType: this.organisationType,
			});
		} catch (e) {
			if (
				window.location.search.includes('token=')  &&
				(window.location.pathname.includes('/flexpos') || window.location.hostname.includes('flexposui'))
			) {
				console.error('[FUNCTION ERROR]');
				this.printObject(null, {
					functionName,
					code: e.code,
					message: e.message,
					details: e.details,
					data,
				});
			} else {
				console.error({
					functionName,
					code: e.code,
					message: e.message,
					details: e.details,
					data,
				});
			}
			if (
				e &&
				e.code === 'unauthenticated' &&
				e.message === 'Organisation on token is not the same as showed organisation'
			) {
				// eslint-disable-next-line no-alert
				alert('Den organisation du har valgt, matcher ikke hvad serveren mener du er har valgt, reload siden.');
				return false;
			}
			if (['unknown', 'resource-exhausted', 'internal', 'unavailable', 'data-loss'].includes(e.code)) {
				if (moment().diff(started, 'seconds') <= 60 && tries <= 7) {
					console.warn(`Error; Try #${tries}, waiting ${tick}ms before trying again`);
					await this._waitFor(tick);
					console.log(`Trying again to request function ${functionName} again..`);
					return this._callFunctionByNameHelper(functionName, data, throwError, tries + 1, tick * 2, started);
				}
			}
			if (throwError) {
				throw e;
			}
			// eslint-disable-next-line no-alert
			alert('Der skete en ukendt fejl, prøv igen eller kontakt Amero Support på 77 34 34 80');
			return false;
		}
	};

	getProjectId = () => {
		return this.storage && this.storage.app && this.storage.app.options_ && this.storage.app.options_.projectId
			? this.storage.app.options_.projectId
			: 'attraction-prod';
	};

	getStorage = () => {
		return this.storage;
	};
}

const instance = new FirebaseService();

export default instance;
