import TagManager from 'react-gtm-module';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { selectGoogleTagManager } from '../store/shared/frontendSlice';
import { useEffect } from 'react';

function TagManagerInit(props) {
	const { googleTagManagerFrontEnd } = useFlags();
	const dispatch = useDispatch();
	const googleTagManagerId = useSelector(selectGoogleTagManager);

	useEffect(() => {
		if (googleTagManagerFrontEnd && googleTagManagerId) {
			TagManager.initialize({
				gtmId: googleTagManagerId,
				events: {
					start: 'start'
				}
			});
		}
	}, [dispatch, googleTagManagerFrontEnd, googleTagManagerId]);

	return props.children;
}

export default TagManagerInit;
